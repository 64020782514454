
/*
function* entries(obj) {
   for (let key of Object.keys(obj)) {
     yield [key, obj[key]];
   }
}
*/
var entries = function (obj) {
   let ret = [];
   for (let key of Object.keys(obj)) {
     ret.push([key, obj[key]]);
   }
   return ret;
}

var isArray = function(arg) {
    //return Object.prototype.toString.call(arg) === '[object Array]';
    return arg.constructor === Array
}

export { entries, isArray }