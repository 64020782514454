
import { ClassFactory } from './classFactory';
import { Classes } from './classes';
import { CurrentUser } from './currentUser';
import { CurrentUserPrefs } from './currentUserPrefs';
import { SimpleObservable } from './simpleObservable';

window.Persistent = {
	ClassFactory: ClassFactory,
	Classes: Classes,
	CurrentUser: CurrentUser,
	CurrentUserPrefs: CurrentUserPrefs,
	SimpleObservable: SimpleObservable,
}