

class CurrentUser {

	//instance = null;
	//logoutRequest = null;
	//classes = null;

	// param: Classes - instance of  Persistent.Classes
	constructor(Classes) {
		this.classes = Classes;
		if (typeof CurrentUser.info === "object") {
			if (CurrentUser.info.__id) {
				// użytkownik zalogowany
				this.instance = Classes.Person.get(CurrentUser.info.__id, CurrentUser.info);
			}
		} else {
			(console.debug||console.log).call(console, "CurrentUser: CurrentUser.info is not an object");
			
      	}
	}
	
	isLoggedIn() {
		return this.instance != null;
	}
	
	logout() {
		var self = this;
		if (!this.logoutRequest) {
			this.logoutRequest = this.classes.Person.logout().then(function(){
				self.instance = null;
				self.logoutRequest = null;
			});
		}
		return this.logoutRequest;
	}

}

CurrentUser.setInfo = json => {
	if (typeof json === 'object' && json !== null) {
		CurrentUser.info = json;
	} else {
		throw "setInfo: Expected an object, got " + typeof json;
	}
}

export { CurrentUser }

