




var SimpleObservable = function(PromiseMaker) {

	let observer = function callAndCatchThePromise() {
		var promise = PromiseMaker.apply(this, arguments);
		if (typeof promise == "object" && typeof promise.then == "function") {
			// returns promise
			promise.then(function(result) {
				for (let callback of observer._subscribers) {
					callback.call(null, result);
				}
			});
		} else {
			// returns straight value
			var result = promise;
			for (let callback of observer._subscribers) {
				callback.call(null, result);
			}
		}		
		return promise;
	}
	observer._subscribers = [];
	observer.subscribe = (func) => {
		observer._subscribers.push(func);
	}
	
	return observer;

}


export { SimpleObservable }